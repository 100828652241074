import { Controller } from "stimulus";
const throttle = require("lodash/throttle");

export default class extends Controller {
  static targets = ["term"];

  initialize() {
    jQuery.expr[":"].contains = jQuery.expr.createPseudo(function (arg) {
      return function (el) {
        return jQuery(el).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
      };
    });

    this.filter = throttle(this.filter, 300);
    this.termTarget.focus();
  }

  filter(evt) {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }

    if (this.term) {
      $(`${this.field}:contains('${this.term}')`)
        .parent()
        .removeClass("hidden");

      $(`${this.field}:not(:contains('${this.term}'))`)
        .parent()
        .addClass("hidden");
    } else {
      $(`${this.field}`).parent().removeClass("hidden");
    }
  }

  get term() {
    return this.termTarget.value;
  }

  get field() {
    return this.data.get("field");
  }
}
