// app/javascript/packs/nested_form.js
document.addEventListener("turbolinks:load", function () {
  function addItemFields(event) {
    let time = new Date().getTime();
    let regexp = new RegExp($(this).data("id"), "g");
    const formRows = document.querySelectorAll('tr.form_rows');
    const lastFormRow = formRows[formRows.length - 1];
    lastFormRow.insertAdjacentHTML('afterend', $(this).data("fields").replace(regexp, time));
  }

  document.querySelectorAll(".add_item_fields").forEach((element) => {
    element.addEventListener("click", addItemFields);
  });
});
