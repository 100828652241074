// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["spinner"];
  connect() {
    console.log("Media connected");
  }

  startUpload(event) {
    this.spinnerTarget.classList.remove("hidden");
  }
}
