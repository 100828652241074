// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["logo", "photo"];

  delete(result) {
    this.photoTarget.parentElement.remove();
  }

  delete_logo(result) {
    this.logoTarget.parentElement.remove();
  }
}
