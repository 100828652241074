// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["stateTotal", "emailTotal", "total"];

  initialize() {
    this.confirmed = false;
  }

  connect() {
    console.log("connected");
  }

  required(e) {
    if (e.currentTarget.value === "") {
      document
        .getElementById(e.currentTarget.dataset.eid)
        .classList.remove("hidden");
    } else {
      document
        .getElementById(e.currentTarget.dataset.eid)
        .classList.add("hidden");
    }
  }

  validateNewForm(e) {
    const local_chamber = document.querySelector('[data-name="Local Chamber"]');

    if (local_chamber.checked) {
      const chamber_select = document.getElementById(
        "registration_chamber_of_commerce_id"
      );
      if (chamber_select.value === "" || chamber_select.value === "none") {
        alert("Please select your chamber of commerce");
        e.preventDefault();
      }
    }
  }

  validateEditForm(e) {
    if (this.stateTotalTarget.value === "") {
      alert("State total employees is required");
      e.preventDefault();
    }
    if (this.emailTotalTarget.value === "") {
      alert("State total employees with email is required");
      e.preventDefault();
    }
    if (this.totalTarget.value === "") {
      alert("Total employees is required");
      e.preventDefault();
    }

    // let ttl = 0;
    // document
    //   .querySelectorAll("input[name^='local_contest_counts']")
    //   .forEach((v) => {
    //     ttl += Number(v.value);
    //   });
    // console.log("TTL", ttl);
    // const idaho_ttl = document.getElementById(
    //   "registration_in_state_employee_count"
    // ).value;

    // if (!this.confirmed && (idaho_ttl / ttl > 1.15 || idaho_ttl / ttl < 0.9)) {
    //   alert(
    //     "Local contest total must equal State Employee Count, please confirm totals and click save again."
    //   );
    //   if (!this.confirmed) {
    //     this.confirmed = true;
    //     console.log(this.confirmed);
    //     e.preventDefault();
    //   }
    // }
  }
}
