// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "sponsorships"];

  connect() {
    console.log("connected");
  }

  toggleSponsorships(e) {
    console.log("clicked");
    if (e.currentTarget.checked) {
      this.sponsorshipsTarget.classList.remove("hidden");
    } else {
      this.sponsorshipsTarget.classList.add("hidden");
    }
  }

  validateForm(event) {
    let valid = false;

    document.querySelectorAll("input[name='local[contest][]']").forEach((v) => {
      if (v.checked === true && v.dataset.type === "Local Contests")
        valid = true;
    });

    if (!valid) {
      alert("You must select at least one Local Contest");
      event.preventDefault();
    }
  }
}
